/*
PNotify 2.0.1 sciactive.com/pnotify/
(C) 2014 Hunter Perrin
license GPL/LGPL/MPL
*/
/*
 * ====== PNotify ======
 *
 * http://sciactive.com/pnotify/
 *
 * Copyright 2009-2014 Hunter Perrin
 *
 * Triple licensed under the GPL, LGPL, and MPL.
 * 	http://gnu.org/licenses/gpl.html
 * 	http://gnu.org/licenses/lgpl.html
 * 	http://mozilla.org/MPL/MPL-1.1.html
 */

// Callbacks
// Uses AMD or browser globals for jQuery.
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as a module.
        define('pnotify.callbacks', ['jquery', 'pnotify'], factory);
    } else {
        // Browser globals
        factory(jQuery, PNotify);
    }
}(function($, PNotify){
	var _init   = PNotify.prototype.init,
		_open   = PNotify.prototype.open,
		_remove = PNotify.prototype.remove;
	PNotify.prototype.init = function(){
		if (this.options.before_init) {
			this.options.before_init(this.options);
		}
		_init.apply(this, arguments);
		if (this.options.after_init) {
			this.options.after_init(this);
		}
	};
	PNotify.prototype.open = function(){
		var ret;
		if (this.options.before_open) {
			ret = this.options.before_open(this);
		}
		if (ret !== false) {
			_open.apply(this, arguments);
			if (this.options.after_open) {
				this.options.after_open(this);
			}
		}
	};
	PNotify.prototype.remove = function(timer_hide){
		var ret;
		if (this.options.before_close) {
			ret = this.options.before_close(this, timer_hide);
		}
		if (ret !== false) {
			_remove.apply(this, arguments);
			if (this.options.after_close) {
				this.options.after_close(this, timer_hide);
			}
		}
	};
}));
